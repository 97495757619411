import api from './api';
import axios from 'axios';
import router from '@/router';

export const state = () => ({
    user: {}
})

export const actions = {
    async get_user ({ commit }, next) {
        try {
            const { data: user } = await axios.get(api.login);
            if (user.name) {
                commit('SET_USER', user);
                next();
            }
        } catch (e) {
            next('/login');
        }
    },
    async login ({ commit }, user) {
        try {
            const { data, status } = await axios.post(api.login, user);
            if (status === 200) {
                commit('SET_USER', data);
                await router.push('/dashboard');
            }
        } catch (e) {

        }
    },
    async logout ({ state, commit }) {
        try {
            await axios.delete(api.logout);
            commit('SET_USER', {});
            await router.push('/login');
        } catch (e) {

        }
    },
    async register ({ commit }, user) {
        try {
            const { data } = await axios.post(api.signup, user)
            commit('SET_STATE', {key: 'accountCreated', value: data})
            commit('SET_STATE', {key: 'emailAlreadyExists', value: false})
        } catch (err) {
            if (err.response?.status === 409) {
                commit('SET_STATE', {key: 'emailAlreadyExists', value: true})
            }
        }
    }
}

export const mutations = {
    SET_STATE (state, { key, value }) {
        state[key] = value;
    },
    SET_USER (state, user) {
        state.user = user;
        console.log(state.user)
    }
};

import axios from 'axios'
import api from './api'
import router from '@/router'

export const state = function () {
    return {
        invoices: [],
        clients: [],
        client: {
            name: 'test',
            address: 'test',
            cif: '123',
            rc: 'test',
            bankIban: 'test',
            bankName: 'test',
            clientEmail: 'test',
            phone: '',
            country: ''
        },
        invoice: {
            client: {
                name: '',
                address: '',
                cif: '',
                rc: '',
                bankIban: '',
                bankName: '',
                clientEmail: ''
            },
            invoiceSeries: 'FSB',
            emitDate: '12-12-12',
            payDate: '12-12-12',
            isPaid: true,
            items: [
                {
                    name: 'test',
                    quantity: 0,
                    measuringUnit: 'buc',
                    vatPercentage: 0,
                    price: 0
                }
            ]
        },
        profile: {
            name: '',
            address: '',
            cif: '',
            rc: '',
            tvaCode: '',
            phone: '',
            bankAccounts: [
                {
                    iban: '389518989ty98f89',
                    bankName: 'Libra Internet'
                }
            ]
        }
    }
}

export const mutations = {
    SET_INVOICES (state, data) {
        state.invoices = data
    },
    SET_INVOICE (state, data) {
        state.invoice = data
    },
    UPDATE_INVOICE_CLIENT (state, {value}) {
        const client = state.clients.find(client => client._id === value);
        state.invoice.client = {...client};
        delete state.invoice.client._id;
        delete state.invoice.client.clientEmail;
        delete state.invoice.client.country;
        delete state.invoice.client.phone;
    },
    RESET_INVOICE (state) {
        state.invoice = {
            client: {
                name: '',
                address: '',
                cif: '',
                rc: '',
                bankIban: '',
                bankName: '',
                clientEmail: ''
            },
            invoiceSeries: '',
            emitDate: '',
            payDate: '',
            isPaid: false,
            items: [{
                name: '',
                quantity: 0,
                measuringUnit: '',
                vatPercentage: 0,
                price: 0
            }]
        };
    },
    UPDATE_INVOICE_FIELD (state, { name, value }) {
        let keys = name.split('.')
        let lastKey = keys.pop()
        let target = keys.reduce((obj, key) => obj[key], state.invoice)
        target[lastKey] = value
    },
    DELETE_INVOICE (state, invoiceId) {
        const index = state.invoices.findIndex(invoice => invoice._id === invoiceId)
        state.invoices.splice(index, 1)
    },
    ADD_INVOICE (state, invoice) {
        state.invoices.push(invoice)
    },
    ADD_ITEM_INVOICE (state) {
        state.invoice.items.push({
            name: '',
            quantity: '',
            measuringUnit: '',
            vatPercentage: '',
            price: ''
        });
    },
    DELETE_ITEM_INVOICE (state) {
        if (state.invoice.items.length > 0) {
            state.invoice.items.splice(-1, 1);
        }
    },
    SET_CLIENTS (state, data) {
        state.clients = data
    },
    SET_CLIENT (state, data) {
        state.client = data
    },
    DELETE_CLIENT (state, clientId) {
        const index = state.clients.findIndex(client => client._id === clientId)
        state.clients.splice(index, 1)
    },
    ADD_CLIENT (state, client) {
        state.clients.push(client)
    },
    UPDATE_CLIENT_FIELD (state, { name, value }) {
        let keys = name.split('.')
        let lastKey = keys.pop()
        let target = keys.reduce((obj, key) => obj[key], state.client)
        target[lastKey] = value
    },
    RESET_CLIENT (state) {
        state.client = {
            name: '',
            address: '',
            cif: '',
            rc: '',
            bankIban: '',
            bankName: '',
            clientEmail: '',
            phone: '',
            country: ''
        };
    },
    SET_PROFILE (state, data) {
        state.profile = data
    },
    ADD_BANK_ACCOUNT_PROFILE (state) {
        state.profile.bankAccounts.push({
            iban: '',
            bankName: ''
        });
    },
    DELETE_BANK_ACCOUNT_PROFILE (state) {
        if (state.profile.bankAccounts.length > 0) {
            state.profile.bankAccounts.splice(-1, 1);
        }
    },
    UPDATE_PROFILE_FIELD (state, { name, value }) {
        let keys = name.split('.')
        let lastKey = keys.pop()
        let target = keys.reduce((obj, key) => obj[key], state.profile)
        target[lastKey] = value
    }
}

export const actions = {
    async get_invoices ({ commit }) {
        try {
            const { data } = await axios(api.invoices)
            commit('SET_INVOICES', data)
        } catch (e) {}
    },
    async get_invoice ({ state, commit }, invoiceId) {
        try {
            const {data} = await axios.get(`${api.invoices}/${invoiceId}`)
            commit('SET_INVOICE', data)
        } catch (e) {}
    },
    async edit_invoice ({ state, commit, dispatch }, invoice) {
        try {
            const {data} = await axios.put(`${api.invoices}/${invoice._id}`, invoice)
            await dispatch('get_invoices');
        } catch (e) {}
    },
    async delete_invoice ({ state, commit }, invoiceId) {
        try {
            await axios.delete(`${api.invoices}/${invoiceId}`)
            commit('DELETE_INVOICE', invoiceId)
        } catch (e) {}
    },
    async create_invoice ({ state, commit }, invoice) {
        try {
            const {data} = await axios.post(api.invoices, invoice)
            commit('ADD_INVOICE', data)
        } catch (e) {}
    },

    async get_clients ({ commit }) {
        try {
            const { data } = await axios(api.clients)
            commit('SET_CLIENTS', data)
        } catch (e) {}
    },
    async get_client ({ state, commit }, clientId) {
        try {
            const {data} = await axios.get(`${api.clients}/${clientId}`)
            commit('SET_CLIENT', data)
        } catch (e) {}
    },
    async edit_client ({ state, commit, dispatch }, client) {
        try {
            const {data} = await axios.put(`${api.clients}/${client._id}`, client)
            await dispatch('get_clients');
        } catch (e) {}
    },
    async delete_client ({ state, commit }, clientId) {
        try {
            await axios.delete(`${api.clients}/${clientId}`)
            commit('DELETE_CLIENT', clientId)
        } catch (e) {}
    },
    async create_client ({ state, commit }, client) {
        try {
            const {data} = await axios.post(api.clients, client)
            commit('ADD_CLIENT', data)
        } catch (e) {}
    },
    async get_profile ({ commit }) {
        try {
            const {data} = await axios.get(`${api.profile}`)
            commit('SET_PROFILE', data)
        } catch (e) {}
    },
    async update_profile ({ state, commit, dispatch }, client) {
        try {
            const {data} = await axios.put(`${api.profile}`, client)
            await dispatch('get_profile');
        } catch (e) {}
    }
}

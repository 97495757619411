import { createStore } from 'vuex';
import * as auth from './modules/auth';
import * as dashboard from './modules/dashboard';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        dashboard
    }
});

import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/dashboard',
        component: () => import('../layouts/DashboardLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('../views/DashboardView.vue')
            },
            {
                path: 'invoices',
                component: () => import('../views/InvoicesView.vue')
            },
            {
                path: 'clients',
                component: () => import('../views/ClientsView.vue')
            },
            {
                path: 'settings',
                component: () => import('../views/SettingsView.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('../views/ProfileView.vue')
                    },
                    {
                        path: 'profile',
                        component: () => import('../views/ProfileView.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    to.name === 'login' || to.name === 'register'
        ? next()
        : store.state.auth.user.name
            ? next()
            : store.dispatch('get_user', next);
});

export default router;
